import APP_CONFIG from "@/apps/core/modules/config.js";
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "stase";
const modelPath = `/${modelName}/`;

class StasePengampu extends ModelBase {
  constructor() {
    let model = {
        id: null,
        angkatan: null,
        mata_kuliah: {id: null, nama: ""},
        mulai: null,
        hingga: null,
        preceptor: {id: null, nama: ""},
        stasepengampu_set: [],
        pengampu_set: []
    };
    let requiredFields = ["pengampu_set"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  reset() {
    super.reset();
    let apiURL = `${APP_CONFIG.baseAPIURL}${modelPath}`;
    this.setApiURL(apiURL);
  }

  getEmptyModel() {
    this.observables.stase.pengampu_set = [];
    return this.observables.stase;
  }

  getPayload() {
    let data = super.getPayload();
    data.pengampu_set = data.pengampu_set.map(obj => obj.id);
    return data;
  }
}

export default StasePengampu;