<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">DAFTARKAN DOSEN PENGAMPU</p>
        </header>
        <div class="card-content events-card">
          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Angkatan</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ stase.angkatan }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Mata Kuliah</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ stase.mata_kuliah.nama }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail is-4 has-text-weight-semibold">Periode</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ stase.mulai }} - {{ stase.hingga }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail is-4 has-text-weight-semibold">Penanggung Jawab</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">
              <template v-if="stase.preceptor">{{ stase.preceptor.nama }}</template>
            </div>
          </div>

          <table class="custom-header table is-fullwidth is-striped is-hoverable is-scrollable">
          <tr class="has-background-white-bis has-text-weight-semibold">
            <th class="pengampu-header">
              Pengampu Terdaftar
            </th>
          </tr>
          <tr>
            <td>
              <b-field class="pengampu-field" grouped group-multiline>
                <div v-for="pengampu in stase.stasepengampu_set" :key="pengampu.id" class="tag-control">
                  <b-tag class="is-grey-res">{{ pengampu.preceptor }}</b-tag>
                </div>
              </b-field>
              <div
                v-if="stase.stasepengampu_set.length == 0"
                class="content has-text-grey has-text-centered"
              >
                <p>Belum ada pengampu yang terdaftar.</p>
              </div>
            </td>
          </tr>
        </table>

          <b-field
            label="Pengampu"
            custom-class="is-small"
            :type="{'is-danger': errorMap.pengampu_set}"
            :message="errorMap.pengampu_set"
          >
            <b-taginput
              v-model="stase.pengampu_set"
              :data="pengampuTags"
              autocomplete
              field="nama"
              icon="tag"
              @typing="getFilteredPengampuTags"
              @input="validateInput('pengampu_set')"
            ></b-taginput>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import StasePengampu from "../models/stasePengampu.js";

export default {
  name: "StasePengampuBatchCreate",
  mixins: [createUpdateMixin],
  data() {
    this.staseMdl = new StasePengampu();
    this.objectMdl = this.staseMdl; // alias yg digunakan di cuMixin
    let obv = this.staseMdl.getObservables();
    obv.pengampuTags = [];
    obv.pengampuList = [];
    return obv;
  },
  computed: {
    isCreate() {
      // selalu true, karena tidak bergantung pada id
      // diperlukan karena akan digunakan sebagai saveContext
      return true;
    }
  },
  methods: {
    getFilteredPengampuTags(text) {
      this.pengampuTags = this.pengampuList.filter(option => {
        return option.nama.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    fetchPengampu() {
      const pengampuURL = `${APP_CONFIG.baseAPIURL}/anggota/dosen/`;
      const params = {stase: this.$route.params.id};
      axios
        .get(pengampuURL, { params: params })
        .then(response => {
          this.pengampuList = response.data;
        })
        .catch(() => {
          this.pengampuList = [];
        });
    },
    fetchData() {
      this.objectMdl.load(this.$route.params.id);
      this.fetchPengampu();
    },
    saveObject() {
      let postURL = `${APP_CONFIG.baseAPIURL}/stase/${this.stase.id}/pengampu/`;
      this.objectMdl.setApiURL(postURL);
      this.objectMdl.save(this.saveContext, () => {
        this.$router.go(-1);
      });
    }
  },
  watch: {
    stase: {
      // stase berubah, update edited
      handler(newValue, oldValue) {
        if (!this.staseMdl.getEdited()) {
          this.setEdited(!!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>


<style scoped lang="scss">
::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.pengampu-header {
  padding-right: unset !important;
}

.pengampu-field {
  margin-bottom: unset !important;
}

.is-grey-res {
  color: white !important;
  background-color: #6d6d6d !important;
}
</style>
